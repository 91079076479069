<script setup>
import { Head } from '@inertiajs/vue3'
import { computed } from 'vue'
const props = defineProps({
  city: String,
  country: String,
});

const cityImage = computed(() => {
  const lowered = props.city.toLowerCase();
  return '/storage/images/cities-premium/' + props.city + '.jpeg';
})
</script>
<template>

  <Head>
    <link rel="preload" as="image"  fetchpriority="high"  :href="cityImage" />
  </Head>
  <div
    class="min-h-[20vh] sm:min-h-[30vh] md:min-h-[50vh] lg:min-h-[65vh] rounded-2xl flex flex-col justify-end p-4 bg-cover bg-center"
    :style="`background-image: url(${cityImage});`">
    <h2 class="font-medium text-white app-title">{{ city }}</h2>
    <p class="font-medium tracking-widest text-white">{{ country }}</p>
  </div>
</template>
<style lang="scss" scoped>
.bg-city-paris {
  @apply bg-paris;
}

.bg-city-new-york {
  @apply bg-new-york;
}

.bg-city-london {
  @apply bg-london;
}

.bg-city-dubai {
  @apply bg-dubai;
}

.bg-city-golden-gate-bridge {
  @apply bg-san-francisco;
}

.bg-city-cancún {
  @apply bg-cancun;
}
</style>
